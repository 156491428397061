<template>

	<el-card class="box-card">
		<!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
		  <el-tab-pane  v-for="(item,index) in classify_list" :label="item.name" > -->
			  <el-table
			      :data="tableData"
				  height="500"
				  border	
			      style="width: 100%">
				    
			      <el-table-column
			        label="ID"
			        prop="id">
			      </el-table-column>
				  <el-table-column
				    label="名称"
				    prop="name">
				  </el-table-column>
			      <el-table-column
			        label="标题"
			        prop="title">
			      </el-table-column>
				  <el-table-column
				    label="关键词"
				    prop="keyword">
				  </el-table-column>
			      <el-table-column
			        label="封面图片"
			        prop="thumbnail">
			  	  <template slot-scope="scope">
			  		  <img style="width: 50px;" :src="imgurl+ scope.row.thumbnail" alt="">
			  	  </template>
			      </el-table-column>
			      <el-table-column
			        label="添加时间"
			        prop="create_time">
			      </el-table-column>
			      <el-table-column
			        label="分类名称"
			        >
			  	  <template slot-scope="scope">
			  		  {{scope.row.cate_name}}
			  		  <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
			  		  <el-button type="text" size="small">编辑</el-button> -->
			  	  </template>
			      </el-table-column>	
			  	<el-table-column
			  	  label="课程类型"
			  	  >
			  	  <template slot-scope="scope">
			  		  {{scope.row.type==1?'图文类':'音视频类'}}
			  		  <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
			  		  <el-button type="text" size="small">编辑</el-button> -->
			  	  </template>
			  	</el-table-column>
			  	<el-table-column
			  	  label="是否免费"
			  	  >
			  	    <template slot-scope="scope">
			  			{{scope.row.ischarge | chargename}} 
			  		</template>
			  	</el-table-column>	
			  	<el-table-column
			  	  label="收费价格"
			  	  >
			  	    <template slot-scope="scope">
			  		{{scope.row.price}} 
			  		
			  		</template>
			  	</el-table-column>
			  			
			      <el-table-column
			        label="VIP价格"
			        prop="desc">
			  	  <template slot-scope="scope">
			  		{{scope.row.vip_price}} 
			  		
			  		</template>
			      </el-table-column>	
			      <el-table-column
			        label="操作"
			  	  width="200"
			        >
			  	  <template slot-scope="scope">
			  		  <!-- <el-switch
			  		  style="margin-right: 10px;"
			  		  @change="change(scope.row)"
			  		    v-model="scope.row.status==1?true:false">
			  		  </el-switch> -->
			  		<!-- <el-button @click="handleClick(scope.row.id)" type="text" size="small">审核</el-button>		 -->
			  		<!-- @click="handleClick(scope.row.id)" -->
					<el-button  type="text" size="small">
						<router-link style="color: #409EFF;" :to="{path:'/Courses/chaptersave',query: {id:scope.row.id,type:scope.row.type}}">查看章节</router-link>
					</el-button>
			  	  <el-button type="text" size="small"><router-link style="color: #409EFF;" :to="{path:'/Courses/addnews',query: {row: JSON.stringify(scope.row),type:'2'}}">编辑</router-link></el-button>
			  	  <el-button @click="newsDelete_click(scope.row.id)" type="text" size="small">删除</el-button>
			  	  <!-- <el-button type="text" size="small"><router-link style="color: #409EFF;" :to="{path:'/Courses/add_addnews',query: {type:scope.row.type,id:scope.row.id}}">添加内容</router-link></el-button> -->
			  	  </template>
			      </el-table-column>	
								  
			    </el-table>
				<el-pagination
				@current-change="handleCurrentChange"
				    layout="prev, pager, next"
				    :total="total">
				  </el-pagination>
		  <!-- </el-tab-pane>
		</el-tabs> -->
		<div slot="header" class="clearfix">
			<router-link :to="{path:'/Courses/addnews',query: {type:'0'}}">
				<el-button type="primary"  size="small">
				+添加课程
				</el-button>
			</router-link>
			<el-input
				style="width: 300px; margin-left:20px; margin-right:20px;"
				size="small"
				@change="keyword"
				placeholder="请输入关键词"
				v-model="keyword_value">
				<i slot="prefix" class="el-input__icon el-icon-search"></i>
			</el-input>
			<el-button type="primary" plain size="small" @click="search">点击筛选</el-button>
			<!-- <el-select style="margin-left: 20px;" size="small" v-model="value" placeholder="请选择">
				<el-option
				v-for="item in classify_list"
				:key="item.id"
				:label="item.name"
				:value="item.id">
				</el-option>
			</el-select> -->
		</div>
		
		<el-dialog
			title="资讯详情"
			:visible.sync="dialogVisible"
			width="30%"
		>
			<!-- <div style="text-align: center; font-weight: 700; line-height: 40px;">{{name}}</div> -->
			<div style="text-align: center; color: #333333;">{{title}}</div>
			<div style="color: #666; font-size: 12px;">{{create_time}}</div>
			<div class="con" style="margin-top: 20px;" v-html="cont"></div>
			<span slot="footer" class="dialog-footer">
				<!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
				<el-button type="primary" @click="dialogVisible = false">关闭</el-button>
			</span>
		</el-dialog>
	</el-card>

</template>
<script>
	import {
	  CourseList,
	  CourseDetailss,
	  CourseDelete,
	  newsDelete,
	  newsChange,
	  classifyList
	} from "@/api/api.js";
	import {getSystemsettings} from '../../api/getSystemsetting.js'
  export default {
    data() {
      return {
		classify_list:[],
		activeName:0,
        tableData: [],
		dialogVisible:false,
		cont:'',
		name:'',
		title:'',
		create_time:'',
		keyword_value:'',
		value:'',
		total:0,
		imgurl:''
      }
    },
	filters:{
		chargename(val){
		    let chargename = '';
		    switch (val) {
		        case 0:
		            chargename = '免费';
		            break;
		        case 1:
		            chargename = 'VIP免费';
		            break;
		        case 2:
		            chargename = '收费';
		            break;
		
		    }
		    return chargename;
		},
	},
	methods:{
		handleClick(){},
		async classifyList() {
		    const { data } = await classifyList(this.listForm);
			console.log(data)
		    if (data.code != 200) return this.$message.error(data.data);
		    this.classify_list = data.data.classify_list;
		},

		search(){
			this.getlist();
		},
		
		change(row){
			// newsChange
			console.log(row)
			if(row.status==1){
				row.status=2
			}else{
				row.status=1
			}
			this.newsChange(row.id,row.status)
		},
		// handleClick(id){
		// 	this.courseDetails(id)
		// 	this.dialogVisible=true
		// },
		newsDelete_click(id){
			this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
			}).then(() => {
				this.news_Delete(id)
			}).catch(() => {
			  this.$message({
				type: 'info',
				message: '已取消删除'
			  });          
			});
			
		},
		async newsChange(id,status) {
		    const { data } = await newsChange({id:id,status:status});
			console.log(data)
		    if (data.code != 200){
				// if(row.status==1){
				// 	row.status=2
				// }else{
				// 	row.status=1
				// }
			 return this.$message.error(data.data);	
			}
		    this.tableData = data.data.goods_list.data;
		},

		handleCurrentChange(val){
			thia.getlist(val)
		},

		async getlist(keyword,val=1) {
		    const { data } = await CourseList({keyword:keyword,page:val});
			console.log(data)
		    if (data.code != 200) return this.$message.error(data.data);
		    this.tableData = data.data.goods_list.data;
			this.total=data.data.goods_list.total
		},

		keyword(){
			this.getlist(this.keyword_value)
		},

		async courseDetails(id) {
		    const { data } = await CourseDetailss({id:id});
			console.log(data)
		    if (data.code != 200) return this.$message.error(data.data);
			this.cont=data.data.content
			this.name=data.data.name
			this.title=data.data.title
			this.create_time=data.data.create_time
		    // this.tableData = data.data.goods_list.data;
		},
		
		async news_Delete(id) {
		    const { data } = await CourseDelete({id:id});
			console.log(data)
		    if (data.code != 200) return this.$message.error(data.data);
			this.$message({
				type: 'success',
				message: '删除成功!'
			});
			this.getlist()
		    // this.tableData = data.data.goods_list.data;
		},
		
	},
	mounted(){
		this.getlist()
		this.classifyList()
		getSystemsettings.then(res=>{
			this.imgurl=res
		})
		
	},
  }
</script>
<style lang="less">
.con img{width: 100%;}
</style>